// src/components/ManageMeters.js
import React, { useEffect, useState } from 'react';
import api from '../utils/api';
import { Typography, Container, Table, TableBody, TableCell, TableHead, TableRow, Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle, Box, Snackbar, Alert } from '@mui/material';

function ManageMeters() {
  const [meters, setMeters] = useState([]);
  const [open, setOpen] = useState(false);
  const [currentMeter, setCurrentMeter] = useState({
    meter_id: '',
    mac_address: '',
    serial_number: '',
    status: 'online',
    calibration: '',
    flow_valve_control: 'OFF',
    meter_type: 'sub',
    flat_id: null,
    building_id: null,
    agency_id: null
  });
  const [isEdit, setIsEdit] = useState(false);
  const [errors, setErrors] = useState({});
  const [notification, setNotification] = useState({ open: false, message: '', severity: 'success' });

  useEffect(() => {
    fetchMeters();
  }, []);

  const fetchMeters = async () => {
    try {
      const response = await api.get('/api/meters');
      setMeters(response.data);
    } catch (error) {
      console.error('Error fetching meters:', error);
    }
  };

  const handleOpen = (meter = {
    meter_id: '',
    mac_address: '',
    serial_number: '',
    status: 'online',
    calibration: '',
    flow_valve_control: 'OFF',
    meter_type: 'sub',
    flat_id: null,
    building_id: null,
    agency_id: null
  }) => {
    setCurrentMeter(meter);
    setIsEdit(!!meter.meter_id);
    setErrors({});
    setOpen(true);
  };

  const handleClose = () => {
    setCurrentMeter({
      meter_id: '',
      mac_address: '',
      serial_number: '',
      status: 'online',
      calibration: '',
      flow_valve_control: 'OFF',
      meter_type: 'sub',
      flat_id: null,
      building_id: null,
      agency_id: null
    });
    setIsEdit(false);
    setOpen(false);
    setErrors({});
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCurrentMeter({ ...currentMeter, [name]: value === '' ? null : value });
    setErrors({ ...errors, [name]: '' });
  };

  const validateFields = () => {
    const newErrors = {};
    if (!currentMeter.mac_address) newErrors.mac_address = 'MAC address is required';
    if (!currentMeter.serial_number) newErrors.serial_number = 'Serial number is required';
    if (!currentMeter.meter_type) newErrors.meter_type = 'Meter type is required';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async () => {
    if (!validateFields()) return;

    const data = {
      meter_id: currentMeter.meter_id || null,
      mac_address: currentMeter.mac_address || null,
      serial_number: currentMeter.serial_number || null,
      status: currentMeter.status || null,
      calibration: currentMeter.calibration || null,
      flow_valve_control: currentMeter.flow_valve_control || null,
      meter_type: currentMeter.meter_type || null,
      flat_id: currentMeter.flat_id || null,
      building_id: currentMeter.building_id || null,
      agency_id: currentMeter.agency_id || null
    };

    try {
      if (isEdit) {
        await api.put(`/api/meters/${currentMeter.meter_id}`, data);
        setNotification({ open: true, message: 'Meter updated successfully!', severity: 'success' });
      } else {
        await api.post('/api/meters', data);
        setNotification({ open: true, message: 'Meter added successfully!', severity: 'success' });
      }
      fetchMeters();
      handleClose();
    } catch (error) {
      console.error('Error saving meter:', error);
      setNotification({ open: true, message: 'Error saving meter. Please try again.', severity: 'error' });
    }
  };

  const handleDelete = async (meter_id) => {
    try {
      await api.delete(`/api/meters/${meter_id}`);
      fetchMeters();
      setNotification({ open: true, message: 'Meter deleted successfully!', severity: 'success' });
    } catch (error) {
      console.error('Error deleting meter:', error);
      setNotification({ open: true, message: 'Error deleting meter. Please try again.', severity: 'error' });
    }
  };

  const handleNotificationClose = () => {
    setNotification({ ...notification, open: false });
  };

  return (
    <Container maxWidth="lg" style={{ paddingLeft: '10px', paddingRight: '10px', paddingBottom: '10px' }}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mb={1}
        mt={1}
        style={{
          borderBottom: '1px solid #ddd',
          padding: '6px 0',
          backgroundColor: '#fff',
          zIndex: 2,
          position: 'sticky',
          top: 0,
        }}
      >
        <Typography variant="h6" style={{ fontWeight: '500', color: '#1976d2' }}>
          Manage Meters
        </Typography>
        <Button variant="contained" color="primary" onClick={() => handleOpen()} style={{ marginBottom: '4px' }}>
          Add Meter
        </Button>
      </Box>

      <Box style={{ overflowY: 'auto', maxHeight: 'calc(100vh - 160px)', marginTop: '5px' }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: 'bold', backgroundColor: '#f8f9fa', padding: '6px 12px' }}>#</TableCell>
              {['MAC Address', 'Serial Number', 'Status', 'Calibration', 'Flow Valve Control', 'Meter Type', 'Flat ID', 'Building ID', 'Agency ID', 'Actions'].map((header) => (
                <TableCell key={header} style={{ fontWeight: 'bold', backgroundColor: '#f8f9fa', padding: '6px 12px', whiteSpace: 'nowrap' }}>
                  {header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {meters.map((meter, index) => (
              <TableRow
                key={meter.meter_id}
                style={{
                  height: '28px',
                  backgroundColor: index % 2 === 0 ? '#fdfdfd' : '#f6f6f6',
                  cursor: 'pointer',
                  transition: 'background-color 0.3s ease',
                }}
                onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = '#e9f7ff')}
                onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = index % 2 === 0 ? '#fdfdfd' : '#f6f6f6')}
              >
                <TableCell style={{ padding: '6px 12px' }}>{index + 1}</TableCell>
                <TableCell style={{ padding: '6px 12px' }}>{meter.mac_address}</TableCell>
                <TableCell style={{ padding: '6px 12px' }}>{meter.serial_number}</TableCell>
                <TableCell style={{ padding: '6px 12px' }}>{meter.status}</TableCell>
                <TableCell style={{ padding: '6px 12px' }}>{meter.calibration}</TableCell>
                <TableCell style={{ padding: '6px 12px' }}>{meter.flow_valve_control}</TableCell>
                <TableCell style={{ padding: '6px 12px' }}>{meter.meter_type}</TableCell>
                <TableCell style={{ padding: '6px 12px' }}>{meter.flat_id}</TableCell>
                <TableCell style={{ padding: '6px 12px' }}>{meter.building_id}</TableCell>
                <TableCell style={{ padding: '6px 12px' }}>{meter.agency_id}</TableCell>
                <TableCell style={{ padding: '6px 12px' }}>
                  <Box display="flex" flexDirection={{ xs: 'column', sm: 'row' }}>
                    <Button variant="outlined" color="secondary" onClick={() => handleOpen(meter)} size="small">
                      Edit
                    </Button>
                    <Button variant="outlined" color="error" onClick={() => handleDelete(meter.meter_id)} style={{ marginLeft: { sm: '8px' } }} size="small">
                      Delete
                    </Button>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{isEdit ? 'Edit Meter' : 'Add Meter'}</DialogTitle>
        <DialogContent>
          {/* Input Fields */}
          <TextField margin="dense" label="MAC Address" name="mac_address" value={currentMeter.mac_address || ''} onChange={handleChange} fullWidth error={!!errors.mac_address} helperText={errors.mac_address} />
          <TextField margin="dense" label="Serial Number" name="serial_number" value={currentMeter.serial_number || ''} onChange={handleChange} fullWidth error={!!errors.serial_number} helperText={errors.serial_number} />
          <TextField margin="dense" label="Status" name="status" select SelectProps={{ native: true }} value={currentMeter.status || 'online'} onChange={handleChange} fullWidth>
            <option value="online">Online</option>
            <option value="offline">Offline</option>
          </TextField>
          <TextField margin="dense" label="Calibration" name="calibration" type="number" value={currentMeter.calibration || ''} onChange={handleChange} fullWidth />
          <TextField margin="dense" label="Flow Valve Control" name="flow_valve_control" select SelectProps={{ native: true }} value={currentMeter.flow_valve_control || 'OFF'} onChange={handleChange} fullWidth>
            <option value="ON">ON</option>
            <option value="OFF">OFF</option>
          </TextField>
          <TextField margin="dense" label="Meter Type" name="meter_type" select SelectProps={{ native: true }} value={currentMeter.meter_type || 'sub'} onChange={handleChange} fullWidth error={!!errors.meter_type} helperText={errors.meter_type}>
            <option value="master">Master</option>
            <option value="sub">Sub</option>
          </TextField>
          <TextField margin="dense" label="Flat ID" name="flat_id" type="number" value={currentMeter.flat_id || ''} onChange={handleChange} fullWidth />
          <TextField margin="dense" label="Building ID" name="building_id" type="number" value={currentMeter.building_id || ''} onChange={handleChange} fullWidth />
          <TextField margin="dense" label="Agency ID" name="agency_id" type="number" value={currentMeter.agency_id || ''} onChange={handleChange} fullWidth />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit}>{isEdit ? 'Update' : 'Add'}</Button>
        </DialogActions>
      </Dialog>

      <Snackbar open={notification.open} autoHideDuration={4000} onClose={handleNotificationClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
        <Alert onClose={handleNotificationClose} severity={notification.severity} sx={{ width: '100%' }}>
          {notification.message}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default ManageMeters;

import React, { useEffect, useState } from 'react';
import api from '../utils/api';
import {
  Typography,
  Container,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box,
  Snackbar,
  Alert,
  CircularProgress,
} from '@mui/material';

function ManageMeterReadings() {
  const [readings, setReadings] = useState([]);
  const [loading, setLoading] = useState(false);
  const [notification, setNotification] = useState({ open: false, message: '', severity: 'success' });

  useEffect(() => {
    fetchMeterReadings();
  }, []);

  const fetchMeterReadings = async () => {
    setLoading(true);
    try {
      const response = await api.get('/api/meter-readings'); // Assuming the endpoint is `/api/meter-readings`
      setReadings(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching meter readings:', error);
      setNotification({ open: true, message: 'Error fetching meter readings. Please try again.', severity: 'error' });
      setLoading(false);
    }
  };

  const handleNotificationClose = () => {
    setNotification({ ...notification, open: false });
  };

  return (
    <Container maxWidth="lg" style={{ paddingLeft: '10px', paddingRight: '10px', paddingBottom: '10px' }}>
      {/* Header Section */}
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mb={1}
        mt={1}
        style={{
          borderBottom: '1px solid #ddd',
          padding: '6px 0',
          backgroundColor: '#fff',
          zIndex: 2,
          position: 'sticky',
          top: 0,
        }}
      >
        <Typography variant="h6" style={{ fontWeight: '500', color: '#1976d2' }}>
          Manage Meter Readings
        </Typography>
      </Box>

      {/* Table Section */}
      <Box style={{ overflowY: 'auto', maxHeight: 'calc(100vh - 160px)', marginTop: '5px' }}>
        {loading ? (
          <Box display="flex" justifyContent="center" alignItems="center" height="100%">
            <CircularProgress />
          </Box>
        ) : (
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell style={{ fontWeight: 'bold', backgroundColor: '#f8f9fa', padding: '6px 12px' }}>#</TableCell>
                {[
                  'Meter ID',
                  'MAC Address',
                  'Serial Number',
                  'Receiving Time',
                  'Reporting Time',
                  'Actual Flow',
                  'Actual Power',
                  'Energy Consumption',
                  'Flow Valve Status',
                  'Inlet Temperature',
                  'Outlet Temperature',
                  'Volume',
                  'Status',
                  'Power Voltage',
                  'Battery Voltage',
                  'Battery Current',
                ].map((header) => (
                  <TableCell
                    key={header}
                    style={{
                      fontWeight: 'bold',
                      backgroundColor: '#f8f9fa',
                      padding: '6px 12px',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {header}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {readings.map((reading, index) => (
                <TableRow
                  key={reading.reading_id}
                  style={{
                    height: '28px',
                    backgroundColor: index % 2 === 0 ? '#fdfdfd' : '#f6f6f6',
                    cursor: 'pointer',
                    transition: 'background-color 0.3s ease',
                  }}
                  onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = '#e9f7ff')}
                  onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = index % 2 === 0 ? '#fdfdfd' : '#f6f6f6')}
                >
                  <TableCell style={{ padding: '6px 12px' }}>{index + 1}</TableCell>
                  <TableCell style={{ padding: '6px 12px' }}>{reading.meter_id}</TableCell>
                  <TableCell style={{ padding: '6px 12px' }}>{reading.mac_address || 'N/A'}</TableCell>
                  <TableCell style={{ padding: '6px 12px' }}>{reading.serial_number}</TableCell>
                  <TableCell style={{ padding: '6px 12px' }}>{new Date(reading.receiving_time).toLocaleString()}</TableCell>
                  <TableCell style={{ padding: '6px 12px' }}>{new Date(reading.reporting_time).toLocaleString()}</TableCell>
                  <TableCell style={{ padding: '6px 12px' }}>{reading.actual_flow || 'N/A'}</TableCell>
                  <TableCell style={{ padding: '6px 12px' }}>{reading.actual_power || 'N/A'}</TableCell>
                  <TableCell style={{ padding: '6px 12px' }}>{reading.energy_consumption || 'N/A'}</TableCell>
                  <TableCell style={{ padding: '6px 12px' }}>{reading.flow_valve_status || 'N/A'}</TableCell>
                  <TableCell style={{ padding: '6px 12px' }}>{reading.inlet_temperature || 'N/A'}</TableCell>
                  <TableCell style={{ padding: '6px 12px' }}>{reading.outlet_temperature || 'N/A'}</TableCell>
                  <TableCell style={{ padding: '6px 12px' }}>{reading.volume || 'N/A'}</TableCell>
                  <TableCell style={{ padding: '6px 12px' }}>{reading.status || 'N/A'}</TableCell>
                  <TableCell style={{ padding: '6px 12px' }}>{reading.power_voltage || 'N/A'}</TableCell>
                  <TableCell style={{ padding: '6px 12px' }}>{reading.battery_voltage || 'N/A'}</TableCell>
                  <TableCell style={{ padding: '6px 12px' }}>{reading.battery_current || 'N/A'}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </Box>

      {/* Snackbar for Notifications */}
      <Snackbar
        open={notification.open}
        autoHideDuration={4000}
        onClose={handleNotificationClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleNotificationClose} severity={notification.severity} sx={{ width: '100%' }}>
          {notification.message}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default ManageMeterReadings;

// src/components/TopNavBar.js
import React from 'react';
import { AppBar, Toolbar, Typography, Button, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';

function TopNavBar({ role, onLogout }) {
  const navigate = useNavigate();

  // Define navigation options based on user role
  const navLinks = {
    superAdmin: [
      { label: 'Dashboard', path: '/' },
      { label: 'Manage Users', path: '/manage-users' },
      { label: 'Manage Agencies', path: '/manage-agencies' },
      { label: 'Manage Buildings', path: '/manage-buildings' },
      { label: 'Manage Meters', path: '/manage-meters' },
      { label: 'Manage Flats', path: '/manage-flats' },
      { label: 'Manage Tenants', path: '/manage-tenants' },
      { label: 'View Readings', path: '/view-meter-readings' },
    ],
    admin: [
      { label: 'Dashboard', path: '/admin-dashboard' },
      { label: 'Manage Meters', path: '/manage-meters' },
      { label: 'Manage Flats', path: '/manage-flats' },
      { label: 'Manage Tenants', path: '/manage-tenants' },
    ],
    agency: [
      { label: 'Dashboard', path: '/agency-dashboard' },
      { label: 'Manage Tenants', path: '/manage-tenants' },
    ],
    client: [
      { label: 'Dashboard', path: '/client-dashboard' },
      { label: 'View Meters', path: '/client-meters' },
    ],
  };

  return (
    <AppBar position="static">
      <Toolbar>
        <Typography variant="h6" style={{ flexGrow: 1 }}>
          {role === 'superAdmin' ? 'Super Admin Dashboard' : `${role.charAt(0).toUpperCase() + role.slice(1)} Dashboard`}
        </Typography>

        {/* Render navigation links based on user role */}
        {navLinks[role]?.map((link) => (
          <Button
            key={link.label}
            color="inherit"
            onClick={() => navigate(link.path)}
          >
            {link.label}
          </Button>
        ))}

        <Button color="inherit" onClick={onLogout}>
          Logout
        </Button>
      </Toolbar>
    </AppBar>
  );
}

export default TopNavBar;

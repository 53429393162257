// src/components/ManageUsers.js
import React, { useEffect, useState } from 'react';
import api from '../utils/api';
import { Typography, Container, Table, TableBody, TableCell, TableHead, TableRow, Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle, Box, Snackbar, Alert, MenuItem } from '@mui/material';

function ManageUsers() {
  const [users, setUsers] = useState([]);
  const [open, setOpen] = useState(false);
  const [resetOpen, setResetOpen] = useState(false);
  const [currentUser, setCurrentUser] = useState({
    user_id: '',
    email: '',
    name: '',
    role: '',
    phone: '',
    status: 'active',
    password: '',
  });
  const [resetPassword, setResetPassword] = useState('');
  const [isEdit, setIsEdit] = useState(false);
  const [errors, setErrors] = useState({});
  const [notification, setNotification] = useState({ open: false, message: '', severity: 'success' });

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await api.get('/api/users');
      setUsers(response.data);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  const handleOpen = (user = {
    user_id: '',
    email: '',
    name: '',
    role: '',
    phone: '',
    status: 'active',
    password: '',
  }) => {
    setCurrentUser(user);
    setIsEdit(!!user.user_id);
    setErrors({});
    setOpen(true);
  };

  const handleResetOpen = (user) => {
    setCurrentUser(user);
    setResetPassword('');
    setResetOpen(true);
  };

  const handleClose = () => {
    setCurrentUser({
      user_id: '',
      email: '',
      name: '',
      role: '',
      phone: '',
      status: 'active',
      password: '',
    });
    setIsEdit(false);
    setOpen(false);
    setErrors({});
  };

  const handleResetClose = () => {
    setResetOpen(false);
    setErrors({});
  };

  const handleChange = (e) => {
    setCurrentUser({ ...currentUser, [e.target.name]: e.target.value });
    setErrors({ ...errors, [e.target.name]: '' });
  };

  const handleResetPasswordChange = (e) => {
    setResetPassword(e.target.value);
    setErrors({ resetPassword: '' });
  };

  const validateFields = () => {
    const newErrors = {};
    if (!currentUser.email) newErrors.email = 'Email is required';
    if (!currentUser.name) newErrors.name = 'Name is required';
    if (!currentUser.role) newErrors.role = 'Role is required';
    if (!currentUser.password && !isEdit) newErrors.password = 'Password is required';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const validateResetPassword = () => {
    if (!resetPassword) {
      setErrors({ resetPassword: 'New password is required' });
      return false;
    }
    return true;
  };

  const handleSubmit = async () => {
    if (!validateFields()) return;

    const data = {
      email: currentUser.email || null,
      name: currentUser.name || null,
      role: currentUser.role || null,
      phone: currentUser.phone || null,
      status: currentUser.status || 'active',
      password: currentUser.password || null,
    };

    try {
      if (isEdit) {
        await api.put(`/api/users/${currentUser.user_id}`, data);
        setNotification({ open: true, message: 'User updated successfully!', severity: 'success' });
      } else {
        await api.post('/api/users', data);
        setNotification({ open: true, message: 'User added successfully!', severity: 'success' });
      }
      fetchUsers();
      handleClose();
    } catch (error) {
      console.error('Error saving user:', error);
      setNotification({ open: true, message: 'Error saving user. Please try again.', severity: 'error' });
    }
  };

  const handleResetPasswordSubmit = async () => {
    if (!validateResetPassword()) return;

    try {
      await api.post('/api/users/reset-password', {
        user_id: currentUser.user_id,
        new_password: resetPassword,
      });
      setNotification({ open: true, message: 'Password reset successfully!', severity: 'success' });
      handleResetClose();
    } catch (error) {
      console.error('Error resetting password:', error);
      setNotification({ open: true, message: 'Error resetting password. Please try again.', severity: 'error' });
    }
  };

  const handleDelete = async (user_id) => {
    try {
      await api.delete(`/api/users/${user_id}`);
      fetchUsers();
      setNotification({ open: true, message: 'User deleted successfully!', severity: 'success' });
    } catch (error) {
      console.error('Error deleting user:', error);
      setNotification({ open: true, message: 'Error deleting user. Please try again.', severity: 'error' });
    }
  };

  const handleNotificationClose = () => {
    setNotification({ ...notification, open: false });
  };

  return (
    <Container maxWidth="lg" style={{ paddingLeft: '10px', paddingRight: '10px', paddingBottom: '10px' }}>
      {/* Header and Button Section */}
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mb={1}
        mt={1}
        style={{
          borderBottom: '1px solid #ddd',
          padding: '6px 0',
          backgroundColor: '#fff',
          zIndex: 2,
          position: 'sticky',
          top: 0,
        }}
      >
        <Typography variant="h6" style={{ fontWeight: '500', color: '#1976d2' }}>
          Manage Users
        </Typography>
        <Button variant="contained" color="primary" onClick={() => handleOpen()} style={{ marginBottom: '4px' }}>
          Add User
        </Button>
      </Box>

      {/* Scrollable Table Section */}
      <Box style={{ overflowY: 'auto', maxHeight: 'calc(100vh - 160px)', marginTop: '5px' }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: 'bold', backgroundColor: '#f8f9fa', padding: '6px 12px' }}>#</TableCell>
              {['Email', 'Name', 'Role', 'Phone', 'Status', 'Actions'].map((header) => (
                <TableCell key={header} style={{ fontWeight: 'bold', backgroundColor: '#f8f9fa', padding: '6px 12px', whiteSpace: 'nowrap' }}>
                  {header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user, index) => (
              <TableRow
                key={user.user_id}
                style={{
                  height: '28px',
                  backgroundColor: index % 2 === 0 ? '#fdfdfd' : '#f6f6f6',
                  cursor: 'pointer',
                  transition: 'background-color 0.3s ease',
                }}
                onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = '#e9f7ff')}
                onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = index % 2 === 0 ? '#fdfdfd' : '#f6f6f6')}
              >
                <TableCell style={{ padding: '6px 12px' }}>{index + 1}</TableCell>
                <TableCell style={{ padding: '6px 12px' }}>{user.email}</TableCell>
                <TableCell style={{ padding: '6px 12px' }}>{user.name}</TableCell>
                <TableCell style={{ padding: '6px 12px' }}>{user.role}</TableCell>
                <TableCell style={{ padding: '6px 12px' }}>{user.phone}</TableCell>
                <TableCell style={{ padding: '6px 12px' }}>{user.status}</TableCell>
                <TableCell style={{ padding: '6px 12px' }}>
                  <Box display="flex" flexDirection={{ xs: 'column', sm: 'row' }}>
                    <Button variant="outlined" color="secondary" onClick={() => handleOpen(user)} size="small">
                      Edit
                    </Button>
                    <Button variant="outlined" color="primary" onClick={() => handleResetOpen(user)} size="small">
                      Reset Password
                    </Button>
                    <Button variant="outlined" color="error" onClick={() => handleDelete(user.user_id)} style={{ marginLeft: { sm: '8px' } }} size="small">
                      Delete
                    </Button>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>

      {/* Add/Edit Dialog */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{isEdit ? 'Edit User' : 'Add User'}</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            label="Email"
            name="email"
            value={currentUser.email || ''}
            onChange={handleChange}
            fullWidth
            error={!!errors.email}
            helperText={errors.email}
          />
          <TextField
            margin="dense"
            label="Name"
            name="name"
            value={currentUser.name || ''}
            onChange={handleChange}
            fullWidth
            error={!!errors.name}
            helperText={errors.name}
          />
          <TextField
            margin="dense"
            label="Role"
            name="role"
            select
            SelectProps={{ native: true }}
            value={currentUser.role || ''}
            onChange={handleChange}
            fullWidth
            error={!!errors.role}
            helperText={errors.role}
          >
            <option value="">Select Role</option>
            <option value="superAdmin">Super Admin</option>
            <option value="admin">Admin</option>
            <option value="agency">Agency</option>
            <option value="buildingManager">Building Manager</option>
            <option value="tenant">Tenant</option>
          </TextField>
          <TextField
            margin="dense"
            label="Phone"
            name="phone"
            value={currentUser.phone || ''}
            onChange={handleChange}
            fullWidth
          />
          <TextField
            margin="dense"
            label="Status"
            name="status"
            select
            SelectProps={{ native: true }}
            value={currentUser.status || 'active'}
            onChange={handleChange}
            fullWidth
          >
            <option value="active">Active</option>
            <option value="inactive">Inactive</option>
          </TextField>
          {!isEdit && (
            <TextField
              margin="dense"
              label="Password"
              name="password"
              type="password"
              value={currentUser.password || ''}
              onChange={handleChange}
              fullWidth
              error={!!errors.password}
              helperText={errors.password}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit}>{isEdit ? 'Update' : 'Add'}</Button>
        </DialogActions>
      </Dialog>

      {/* Reset Password Dialog */}
      <Dialog open={resetOpen} onClose={handleResetClose}>
        <DialogTitle>Reset Password</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            label="New Password"
            name="resetPassword"
            type="password"
            value={resetPassword || ''}
            onChange={handleResetPasswordChange}
            fullWidth
            error={!!errors.resetPassword}
            helperText={errors.resetPassword}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleResetClose}>Cancel</Button>
          <Button onClick={handleResetPasswordSubmit}>Reset</Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar for Notifications */}
      <Snackbar open={notification.open} autoHideDuration={4000} onClose={handleNotificationClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
        <Alert onClose={handleNotificationClose} severity={notification.severity} sx={{ width: '100%' }}>
          {notification.message}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default ManageUsers;

// src/components/ManageTenants.js
import React, { useEffect, useState } from 'react';
import api from '../utils/api';
import {
  Typography,
  Container,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  Snackbar,
  Alert,
} from '@mui/material';
import dayjs from 'dayjs'; // To handle date formatting

function ManageTenants() {
  const [tenants, setTenants] = useState([]);
  const [open, setOpen] = useState(false);
  const [currentTenant, setCurrentTenant] = useState({
    tenant_id: '',
    name: '',
    email: '',
    phone: '',
    start_date: '',
    end_date: '',
    status: 'active',
    flat_id: '',
  });
  const [isEdit, setIsEdit] = useState(false);
  const [errors, setErrors] = useState({});
  const [notification, setNotification] = useState({
    open: false,
    message: '',
    severity: 'success',
  });

  useEffect(() => {
    fetchTenants();
  }, []);

  const fetchTenants = async () => {
    try {
      const response = await api.get('/api/tenants');
      const formattedData = response.data.map((tenant) => ({
        ...tenant,
        start_date: tenant.start_date ? dayjs(tenant.start_date).format('YYYY-MM-DD') : '',
        end_date: tenant.end_date ? dayjs(tenant.end_date).format('YYYY-MM-DD') : '',
      }));
      setTenants(formattedData);
    } catch (error) {
      console.error('Error fetching tenants:', error);
    }
  };

  const handleOpen = (tenant = {
    tenant_id: '',
    name: '',
    email: '',
    phone: '',
    start_date: '',
    end_date: '',
    status: 'active',
    flat_id: '',
  }) => {
    setCurrentTenant(tenant);
    setIsEdit(!!tenant.tenant_id);
    setErrors({});
    setOpen(true);
  };

  const handleClose = () => {
    setCurrentTenant({
      tenant_id: '',
      name: '',
      email: '',
      phone: '',
      start_date: '',
      end_date: '',
      status: 'active',
      flat_id: '',
    });
    setIsEdit(false);
    setOpen(false);
    setErrors({});
  };

  const handleChange = (e) => {
    setCurrentTenant({ ...currentTenant, [e.target.name]: e.target.value });
    setErrors({ ...errors, [e.target.name]: '' });
  };

  const validateFields = () => {
    const newErrors = {};
    if (!currentTenant.name) newErrors.name = 'Name is required';
    if (!currentTenant.start_date) newErrors.start_date = 'Start Date is required';
    if (currentTenant.end_date && currentTenant.end_date < currentTenant.start_date) {
      newErrors.end_date = 'End Date cannot be earlier than Start Date';
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async () => {
    if (!validateFields()) return;

    const data = {
      name: currentTenant.name || null,
      email: currentTenant.email || null,
      phone: currentTenant.phone || null,
      start_date: currentTenant.start_date || null,
      end_date: currentTenant.end_date || null,
      status: currentTenant.status || null,
      flat_id: currentTenant.flat_id || null,
    };

    try {
      if (isEdit) {
        await api.put(`/api/tenants/${currentTenant.tenant_id}`, data);
        setNotification({
          open: true,
          message: 'Tenant updated successfully!',
          severity: 'success',
        });
      } else {
        await api.post('/api/tenants', data);
        setNotification({
          open: true,
          message: 'Tenant added successfully!',
          severity: 'success',
        });
      }
      fetchTenants();
      handleClose();
    } catch (error) {
      console.error('Error saving tenant:', error);
      setNotification({
        open: true,
        message: 'Error saving tenant. Please try again.',
        severity: 'error',
      });
    }
  };

  const handleDelete = async (tenant_id) => {
    try {
      await api.delete(`/api/tenants/${tenant_id}`);
      fetchTenants();
      setNotification({
        open: true,
        message: 'Tenant deleted successfully!',
        severity: 'success',
      });
    } catch (error) {
      console.error('Error deleting tenant:', error);
      setNotification({
        open: true,
        message: 'Error deleting tenant. Please try again.',
        severity: 'error',
      });
    }
  };

  const handleNotificationClose = () => {
    setNotification({ ...notification, open: false });
  };

  return (
    <Container
      maxWidth="lg"
      style={{ paddingLeft: '10px', paddingRight: '10px', paddingBottom: '10px' }}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mb={1}
        mt={1}
        style={{
          borderBottom: '1px solid #ddd',
          padding: '6px 0',
          backgroundColor: '#fff',
          zIndex: 2,
          position: 'sticky',
          top: 0,
        }}
      >
        <Typography variant="h6" style={{ fontWeight: '500', color: '#1976d2' }}>
          Manage Tenants
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleOpen()}
          style={{ marginBottom: '4px' }}
        >
          Add Tenant
        </Button>
      </Box>
      <Box style={{ overflowY: 'auto', maxHeight: 'calc(100vh - 160px)', marginTop: '5px' }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: 'bold', backgroundColor: '#f8f9fa', padding: '6px 12px' }}>
                #
              </TableCell>
              {['Name', 'Email', 'Phone', 'Start Date', 'End Date', 'Status', 'Flat ID', 'Actions'].map(
                (header) => (
                  <TableCell
                    key={header}
                    style={{
                      fontWeight: 'bold',
                      backgroundColor: '#f8f9fa',
                      padding: '6px 12px',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {header}
                  </TableCell>
                )
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {tenants.map((tenant, index) => (
              <TableRow
                key={tenant.tenant_id}
                style={{
                  height: '28px',
                  backgroundColor: index % 2 === 0 ? '#fdfdfd' : '#f6f6f6',
                  cursor: 'pointer',
                  transition: 'background-color 0.3s ease',
                }}
                onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = '#e9f7ff')}
                onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = index % 2 === 0 ? '#fdfdfd' : '#f6f6f6')}
              >
                <TableCell style={{ padding: '6px 12px' }}>{index + 1}</TableCell>
                <TableCell style={{ padding: '6px 12px' }}>{tenant.name}</TableCell>
                <TableCell style={{ padding: '6px 12px' }}>{tenant.email}</TableCell>
                <TableCell style={{ padding: '6px 12px' }}>{tenant.phone}</TableCell>
                <TableCell style={{ padding: '6px 12px' }}>{tenant.start_date}</TableCell>
                <TableCell style={{ padding: '6px 12px' }}>{tenant.end_date}</TableCell>
                <TableCell style={{ padding: '6px 12px' }}>{tenant.status}</TableCell>
                <TableCell style={{ padding: '6px 12px' }}>{tenant.flat_id}</TableCell>
                <TableCell style={{ padding: '6px 12px' }}>
                  <Box display="flex" flexDirection={{ xs: 'column', sm: 'row' }}>
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={() => handleOpen(tenant)}
                      size="small"
                    >
                      Edit
                    </Button>
                    <Button
                      variant="outlined"
                      color="error"
                      onClick={() => handleDelete(tenant.tenant_id)}
                      style={{ marginLeft: { sm: '8px' } }}
                      size="small"
                    >
                      Delete
                    </Button>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>

      {/* Add/Edit Dialog */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{isEdit ? 'Edit Tenant' : 'Add Tenant'}</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            label="Name"
            name="name"
            value={currentTenant.name || ''}
            onChange={handleChange}
            fullWidth
            error={!!errors.name}
            helperText={errors.name}
          />
          <TextField
            margin="dense"
            label="Email"
            name="email"
            value={currentTenant.email || ''}
            onChange={handleChange}
            fullWidth
            error={!!errors.email}
            helperText={errors.email}
          />
          <TextField
            margin="dense"
            label="Phone"
            name="phone"
            value={currentTenant.phone || ''}
            onChange={handleChange}
            fullWidth
            error={!!errors.phone}
            helperText={errors.phone}
          />
          <TextField
            margin="dense"
            label="Status"
            name="status"
            select
            SelectProps={{ native: true }}
            value={currentTenant.status || 'active'}
            onChange={handleChange}
            fullWidth
          >
            <option value="active">Active</option>
            <option value="inactive">Inactive</option>
          </TextField>
          <TextField
            margin="dense"
            label="Flat ID"
            name="flat_id"
            type="number"
            value={currentTenant.flat_id || ''}
            onChange={handleChange}
            fullWidth
            error={!!errors.flat_id}
            helperText={errors.flat_id}
          />
          <TextField
            margin="dense"
            label="Start Date"
            name="start_date"
            type="date"
            value={currentTenant.start_date || ''}
            onChange={handleChange}
            fullWidth
            InputLabelProps={{ shrink: true }}
            error={!!errors.start_date}
            helperText={errors.start_date}
          />
          <TextField
            margin="dense"
            label="End Date"
            name="end_date"
            type="date"
            value={currentTenant.end_date || ''}
            onChange={handleChange}
            fullWidth
            InputLabelProps={{ shrink: true }}
            error={!!errors.end_date}
            helperText={errors.end_date}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit}>{isEdit ? 'Update' : 'Add'}</Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar for Notifications */}
      <Snackbar
        open={notification.open}
        autoHideDuration={4000}
        onClose={handleNotificationClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleNotificationClose} severity={notification.severity} sx={{ width: '100%' }}>
          {notification.message}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default ManageTenants;

// src/App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Dashboard from './pages/Dashboard';
import SuperAdminDashboard from './components/SuperAdminDashboard';
import AdminDashboard from './components/AdminDashboard';
import AgencyDashboard from './components/AgencyDashboard';
import TenantsDashboard from './components/TenantsDashboard';
import ManageUsers from './components/ManageUsers';
import ManageMeters from './components/ManageMeters';
import ManageFlats from './components/ManageFlats';
import ManageTenants from './components/ManageTenants';
import ManageAgencies from './components/ManageAgencies';
import ManageBuildings from './components/ManageBuildings';
import Login from './pages/Login';
import TopNavBar from './components/TopNavBar';
import ErrorBoundary from './components/ErrorBoundary';
import ManageMeterReadings from './components/ManageMeterReadings';

function App() {
  const [auth, setAuth] = useState({
    token: localStorage.getItem('token'),
    role: localStorage.getItem('role'),
  });

  console.log("Initial auth state:", auth); // Log initial state

  const handleLogout = () => {

    console.log("Logging out..."); // Log logout action

    localStorage.removeItem('token');
    localStorage.removeItem('role');
    setAuth({ token: null, role: null });
  };

  // ProtectedRoute component for authenticated and role-based access
  const ProtectedRoute = ({ roleRequired, children }) => {
    console.log("ProtectedRoute - Required role:", roleRequired, "User role:", auth.role); // Log role check

    if (!auth.token) {
      console.log("No token found. Redirecting to login."); // Log redirection if no token

      return <Navigate to="/login" />;
    }
    if (roleRequired && auth.role !== roleRequired) {
      console.log("Insufficient permissions. Redirecting to dashboard."); // Log permission issue

      return <Navigate to="/" />;
    }
    return children;
  };

  return (
    <ErrorBoundary>
      <Router>
        {auth.token && <TopNavBar role={auth.role} onLogout={handleLogout} />}
        <Routes>
          <Route path="/login" element={<Login setAuth={setAuth} />} />
          
          {/* Super Admin Dashboard */}
          <Route path="/" element={<ProtectedRoute roleRequired="superAdmin"><SuperAdminDashboard /></ProtectedRoute>} />
          <Route path="/manage-users" element={<ProtectedRoute roleRequired="superAdmin"><ManageUsers /></ProtectedRoute>} />
          <Route path="/manage-agencies" element={<ProtectedRoute roleRequired="superAdmin"><ManageAgencies /></ProtectedRoute>} />
          <Route path="/manage-meters" element={<ProtectedRoute roleRequired="superAdmin"><ManageMeters /></ProtectedRoute>} />
          <Route path="/manage-flats" element={<ProtectedRoute roleRequired="superAdmin"><ManageFlats /></ProtectedRoute>} />
          <Route path="/manage-tenants" element={<ProtectedRoute roleRequired="superAdmin"><ManageTenants /></ProtectedRoute>} />
          <Route path="/manage-buildings" element={<ProtectedRoute roleRequired="superAdmin"><ManageBuildings /></ProtectedRoute>} />
          <Route path="/view-meter-readings" element={<ProtectedRoute roleRequired="superAdmin"><ManageMeterReadings /></ProtectedRoute>} />

          {/* Admin Dashboard */}
          <Route path="/admin-dashboard" element={<ProtectedRoute roleRequired="admin"><AdminDashboard /></ProtectedRoute>} />
          
          {/* Agency Dashboard */}
          <Route path="/agency-dashboard" element={<ProtectedRoute roleRequired="agency"><AgencyDashboard /></ProtectedRoute>} />

          {/* Tenants Dashboard */}
          <Route path="/tenants-dashboard" element={<ProtectedRoute roleRequired="tenants"><TenantsDashboard /></ProtectedRoute>} />

          {/* Catch-all Route */}
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </Router>
    </ErrorBoundary>
  );
}

export default App;

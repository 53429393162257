// src/components/ManageAgencies.js
import React, { useEffect, useState } from 'react';
import api from '../utils/api';
import { Typography, Container, Table, TableBody, TableCell, TableHead, TableRow, Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle, Box, Snackbar, Alert } from '@mui/material';

function ManageAgencies() {
  const [agencies, setAgencies] = useState([]);
  const [open, setOpen] = useState(false);
  const [currentAgency, setCurrentAgency] = useState({
    agency_id: '',
    name: '',
    contact_phone: '',
    contact_email: ''
  });
  const [isEdit, setIsEdit] = useState(false);
  const [errors, setErrors] = useState({});
  const [notification, setNotification] = useState({ open: false, message: '', severity: 'success' });

  useEffect(() => {
    fetchAgencies();
  }, []);

  const fetchAgencies = async () => {
    try {
      const response = await api.get('/api/agencies');
      setAgencies(response.data);
    } catch (error) {
      console.error('Error fetching agencies:', error);
    }
  };

  const handleOpen = (agency = { agency_id: '', name: '', contact_phone: '', contact_email: '' }) => {
    setCurrentAgency(agency);
    setIsEdit(!!agency.agency_id);
    setErrors({});
    setOpen(true);
  };

  const handleClose = () => {
    setCurrentAgency({ agency_id: '', name: '', contact_phone: '', contact_email: '' });
    setIsEdit(false);
    setOpen(false);
    setErrors({});
  };

  const handleChange = (e) => {
    setCurrentAgency({ ...currentAgency, [e.target.name]: e.target.value });
    setErrors({ ...errors, [e.target.name]: '' });
  };

  const validateFields = () => {
    const newErrors = {};
    if (!currentAgency.name) newErrors.name = 'Name is required';
    if (!currentAgency.contact_phone) newErrors.contact_phone = 'Phone number is required';
    if (!currentAgency.contact_email) newErrors.contact_email = 'Email is required';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async () => {
    if (!validateFields()) return;

    const data = {
      name: currentAgency.name || null,
      contact_phone: currentAgency.contact_phone || null,
      contact_email: currentAgency.contact_email || null
    };

    try {
      if (isEdit) {
        await api.put(`/api/agencies/${currentAgency.agency_id}`, data);
        setNotification({ open: true, message: 'Agency updated successfully!', severity: 'success' });
      } else {
        await api.post('/api/agencies', data);
        setNotification({ open: true, message: 'Agency added successfully!', severity: 'success' });
      }
      fetchAgencies();
      handleClose();
    } catch (error) {
      console.error('Error saving agency:', error);
      setNotification({ open: true, message: 'Error saving agency. Please try again.', severity: 'error' });
    }
  };

  const handleDelete = async (agency_id) => {
    try {
      await api.delete(`/api/agencies/${agency_id}`);
      fetchAgencies();
      setNotification({ open: true, message: 'Agency deleted successfully!', severity: 'success' });
    } catch (error) {
      console.error('Error deleting agency:', error);
      setNotification({ open: true, message: 'Error deleting agency. Please try again.', severity: 'error' });
    }
  };

  const handleNotificationClose = () => {
    setNotification({ ...notification, open: false });
  };

  return (
    <Container maxWidth="lg" style={{ paddingLeft: '10px', paddingRight: '10px', paddingBottom: '10px' }}>
      {/* Header and Button Section */}
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mb={1}
        mt={1}
        style={{
          borderBottom: '1px solid #ddd',
          padding: '6px 0',
          backgroundColor: '#fff',
          zIndex: 2,
          position: 'sticky',
          top: 0,
        }}
      >
        <Typography variant="h6" style={{ fontWeight: '500', color: '#1976d2' }}>
          Manage Agencies
        </Typography>
        <Button variant="contained" color="primary" onClick={() => handleOpen()} style={{ marginBottom: '4px' }}>
          Add Agency
        </Button>
      </Box>

      {/* Scrollable Table Section */}
      <Box style={{ overflowY: 'auto', maxHeight: 'calc(100vh - 160px)', marginTop: '5px' }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: 'bold', backgroundColor: '#f8f9fa', padding: '6px 12px' }}>#</TableCell>
              {['Name', 'Contact Phone', 'Contact Email', 'Actions'].map((header) => (
                <TableCell key={header} style={{ fontWeight: 'bold', backgroundColor: '#f8f9fa', padding: '6px 12px', whiteSpace: 'nowrap' }}>
                  {header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {agencies.map((agency, index) => (
              <TableRow
                key={agency.agency_id}
                style={{
                  height: '28px',
                  backgroundColor: index % 2 === 0 ? '#fdfdfd' : '#f6f6f6',
                  cursor: 'pointer',
                  transition: 'background-color 0.3s ease',
                }}
                onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = '#e9f7ff')}
                onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = index % 2 === 0 ? '#fdfdfd' : '#f6f6f6')}
              >
                <TableCell style={{ padding: '6px 12px' }}>{index + 1}</TableCell>
                <TableCell style={{ padding: '6px 12px' }}>{agency.name}</TableCell>
                <TableCell style={{ padding: '6px 12px' }}>{agency.contact_phone}</TableCell>
                <TableCell style={{ padding: '6px 12px' }}>{agency.contact_email}</TableCell>
                <TableCell style={{ padding: '6px 12px' }}>
                  <Box display="flex" flexDirection={{ xs: 'column', sm: 'row' }}>
                    <Button variant="outlined" color="secondary" onClick={() => handleOpen(agency)} size="small">
                      Edit
                    </Button>
                    <Button variant="outlined" color="error" onClick={() => handleDelete(agency.agency_id)} style={{ marginLeft: { sm: '8px' } }} size="small">
                      Delete
                    </Button>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>

      {/* Add/Edit Dialog */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{isEdit ? 'Edit Agency' : 'Add Agency'}</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            label="Name"
            name="name"
            value={currentAgency.name || ''}
            onChange={handleChange}
            fullWidth
            error={!!errors.name}
            helperText={errors.name}
          />
          <TextField
            margin="dense"
            label="Contact Phone"
            name="contact_phone"
            value={currentAgency.contact_phone || ''}
            onChange={handleChange}
            fullWidth
            error={!!errors.contact_phone}
            helperText={errors.contact_phone}
          />
          <TextField
            margin="dense"
            label="Contact Email"
            name="contact_email"
            value={currentAgency.contact_email || ''}
            onChange={handleChange}
            fullWidth
            error={!!errors.contact_email}
            helperText={errors.contact_email}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit}>{isEdit ? 'Update' : 'Add'}</Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar for Notifications */}
      <Snackbar
        open={notification.open}
        autoHideDuration={4000}
        onClose={handleNotificationClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleNotificationClose} severity={notification.severity} sx={{ width: '100%' }}>
          {notification.message}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default ManageAgencies;

// src/components/SuperAdminDashboard.js
import React, { useState, useEffect } from 'react';
import { Typography, Grid, Paper } from '@mui/material';
import MeterMapWithReadings from './MeterMapWithReadings';
import MeterMap from './MeterMap';

function SuperAdminDashboard() {
  // Placeholder data for statistics
  const [totalMeters, setTotalMeters] = useState(0);
  const [activeUsers, setActiveUsers] = useState(0);

  // Fetch or calculate statistics
  useEffect(() => {
    // Example fetch calls (replace with actual API calls)
    setTotalMeters(150); // Example static data
    setActiveUsers(85);   // Example static data
  }, []);

  return (
    <Grid container spacing={3} style={{ padding: '20px' }}>
      {/* Statistics Section */}
      <Grid item xs={12} md={6}>
        <Paper style={{ padding: '20px', textAlign: 'center' }}>
          <Typography variant="h6">Total Meters</Typography>
          <Typography variant="h4">{totalMeters}</Typography>
        </Paper>
      </Grid>

      <Grid item xs={12} md={6}>
        <Paper style={{ padding: '20px', textAlign: 'center' }}>
          <Typography variant="h6">Active Users</Typography>
          <Typography variant="h4">{activeUsers}</Typography>
        </Paper>
      </Grid>

      {/* Placeholder for additional statistics */}
      <Grid item xs={12} md={6}>
        <Paper style={{ padding: '20px', textAlign: 'center' }}>
          <Typography variant="h6">New Meters This Month</Typography>
          <Typography variant="h4">20</Typography>
        </Paper>
      </Grid>

      <Grid item xs={12} md={6}>
        <Paper style={{ padding: '20px', textAlign: 'center' }}>
          <Typography variant="h6">Monthly Energy Consumption</Typography>
          <Typography variant="h4">1500 kWh</Typography>
        </Paper>
      </Grid>

      {/* Meter Map with Readings */}
      <Grid item xs={12}>
        <Paper style={{ padding: '20px' }}>
          <Typography variant="h6" gutterBottom>
            Meter Locations
          </Typography>
          <MeterMap />
        </Paper>
      </Grid>
    </Grid>
  );
}

export default SuperAdminDashboard;

// src/components/MeterMap.js
import React, { useEffect, useState } from 'react';
import { GoogleMap, Marker, InfoWindow, useJsApiLoader } from '@react-google-maps/api';
import { Typography, CircularProgress, Box } from '@mui/material';
import api from '../utils/api';

const mapContainerStyle = {
  width: '100%',
  height: '600px',
};

const center = {
  lat: 25.276987, // Example center coordinates
  lng: 55.296249,
};

function MeterMap() {
  const [meters, setMeters] = useState([]);
  const [selectedMeter, setSelectedMeter] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  // Load the Google Maps script using the library
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  });

  useEffect(() => {
    const fetchMeterLocations = async () => {
      const token = localStorage.getItem('token'); // Retrieve the token from localStorage
      if (!token) {
        console.error('No token found. Please log in again.');
        setError('No authentication token found. Please log in.');
        setLoading(false);
        return;
      }

      try {
        const response = await api.get('/api/meters');
        setMeters(response.data);
        setLoading(false);
      } catch (err) {
        console.error("Error fetching meter locations:", err.response ? err.response.data : err.message);
        setError('Failed to load meter locations. Please try logging in again.');
        setLoading(false);

        // If unauthorized, redirect to login
        if (err.response && err.response.status === 401) {
          window.location.href = '/login';
        }
      }
    };

    fetchMeterLocations();
  }, []);

  if (loadError) return <Typography color="error">Error loading maps</Typography>;
  if (!isLoaded) return <CircularProgress />;

  return (
    <Box>
      <Typography variant="h5" gutterBottom>
        Meter Locations
      </Typography>
      {loading && <CircularProgress />}
      {error && <Typography color="error">{error}</Typography>}
      {!loading && !error && (
        <GoogleMap
          mapContainerStyle={mapContainerStyle}
          zoom={12}
          center={center}
        >
          {meters.map((meter) => (
            <Marker
              key={meter.meter_id}
              position={{
                lat: parseFloat(meter.latitude),
                lng: parseFloat(meter.longitude),
              }}
              onClick={() => setSelectedMeter(meter)}
              icon={{
                url: meter.status === 'online'
                  ? 'http://maps.google.com/mapfiles/ms/icons/green-dot.png'
                  : 'http://maps.google.com/mapfiles/ms/icons/red-dot.png',
              }}
            />
          ))}

          {selectedMeter && (
            <InfoWindow
              position={{
                lat: parseFloat(selectedMeter.latitude),
                lng: parseFloat(selectedMeter.longitude),
              }}
              onCloseClick={() => setSelectedMeter(null)}
            >
              <div>
                <Typography variant="subtitle1">Meter ID: {selectedMeter.meter_id}</Typography>
                <Typography variant="body2">Status: {selectedMeter.status}</Typography>
                {selectedMeter.building_id && (
                  <Typography variant="body2">Building ID: {selectedMeter.building_id}</Typography>
                )}
                {selectedMeter.flat_id && (
                  <Typography variant="body2">Flat ID: {selectedMeter.flat_id}</Typography>
                )}
              </div>
            </InfoWindow>
          )}
        </GoogleMap>
      )}
    </Box>
  );
}

export default MeterMap;

// src/components/Login.js
import React, { useRef, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { TextField, Button, Typography, Container } from '@mui/material';

function Login({ setAuth }) {
  const emailRef = useRef();
  const passwordRef = useRef();
  const navigate = useNavigate();
  const [error, setError] = useState('');

  const handleLogin = async (e) => {
    e.preventDefault();
    const email = emailRef.current.value;
    const password = passwordRef.current.value;

    try {
      setError('');
      console.log("Attempting login with:", email, password);

      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/login`, { email, password });
      console.log("Login response data:", response.data);

      const { token, role } = response.data;
      console.log("Token:", token, "Role:", role);

      // Save token and role to local storage
      localStorage.setItem('token', token);
      localStorage.setItem('role', role);
      setAuth({ token, role });
      navigate('/'); // Redirect to the dashboard
    } catch (err) {
      console.error("Login error:", err);
      setError('Invalid email or password');
    }
  };

  return (
    <Container maxWidth="xs">
      <Typography variant="h4" gutterBottom>
        Login
      </Typography>
      {error && <Typography color="error">{error}</Typography>}
      <form onSubmit={handleLogin}>
        <TextField
          label="Email"
          type="email"
          inputRef={emailRef}
          required
          fullWidth
          margin="normal"
        />
        <TextField
          label="Password"
          type="password"
          inputRef={passwordRef}
          required
          fullWidth
          margin="normal"
        />
        <Button type="submit" variant="contained" color="primary" fullWidth>
          Login
        </Button>
      </form>
    </Container>
  );
}

export default Login;

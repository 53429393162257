import axios from 'axios';

const api = axios.create({
  //baseURL: 'http://localhost:3000', // Backend server URL
  baseURL: process.env.REACT_APP_API_URL, // Use environment variable

});

// Add a request interceptor to attach the token to each request
api.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`; // Set Authorization header

      console.log("Authorization Header:", config.headers['Authorization']); // Log the header to confirm token is attached
    
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);



/*
// Handle expired tokens in the response
api.interceptors.response.use(
    response => response,
    error => {
      if (error.response && error.response.status === 401) {
        const errorMessage = error.response.data.message;
        if (errorMessage === 'jwt expired') {
          console.log("Token expired. Redirecting to login.");
  
          // Option 1: Prompt user to log in again
          localStorage.removeItem('token');
          window.location.href = '/login';
  
          // Option 2: If using a refresh token, call the refresh endpoint here (see Step 2 below)
          // return refreshAccessToken().then(() => {
          //   error.config.headers['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
          //   return api.request(error.config);
          // });
        }
      }
      return Promise.reject(error);
    }
  );
  */

// Handle expired or invalid tokens in the response
api.interceptors.response.use(
    response => response,
    error => {
      if (error.response && (error.response.status === 401 || error.response.status === 403)) {
        const errorMessage = error.response.data.message || error.response.statusText;
        console.log("Authorization error:", errorMessage); // Log to check the actual error
  
        if (errorMessage === 'jwt expired' || error.response.status === 403) {
          // Clear token and redirect to login
          localStorage.removeItem('token');
          window.location.href = '/login';
        }
      }
      return Promise.reject(error);
    }
  );



export default api;

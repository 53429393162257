import React, { useEffect, useState } from 'react';
import api from '../utils/api';
import { Typography, Container, Table, TableBody, TableCell, TableHead, TableRow, Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle, Box, Snackbar, Alert } from '@mui/material';

function ManageBuildings() {
  const [buildings, setBuildings] = useState([]);
  const [open, setOpen] = useState(false);
  const [currentBuilding, setCurrentBuilding] = useState({
    building_id: '',
    name: '',
    address: '',
    city: '',
    zone: '',
    latitude: '',
    longitude: '',
    agency_id: '',
    manager_id: '',
    master_meter_id: '',
  });
  const [isEdit, setIsEdit] = useState(false);
  const [errors, setErrors] = useState({});
  const [notification, setNotification] = useState({ open: false, message: '', severity: 'success' });

  useEffect(() => {
    fetchBuildings();
  }, []);

  const fetchBuildings = async () => {
    try {
      const response = await api.get('/api/buildings');
      setBuildings(response.data);
    } catch (error) {
      console.error('Error fetching buildings:', error);
    }
  };

  const handleOpen = (building = {
    building_id: '',
    name: '',
    address: '',
    city: '',
    zone: '',
    latitude: '',
    longitude: '',
    agency_id: '',
    manager_id: '',
    master_meter_id: '',
  }) => {
    setCurrentBuilding(building);
    setIsEdit(!!building.building_id);
    setErrors({});
    setOpen(true);
  };

  const handleClose = () => {
    setCurrentBuilding({
      building_id: '',
      name: '',
      address: '',
      city: '',
      zone: '',
      latitude: '',
      longitude: '',
      agency_id: '',
      manager_id: '',
      master_meter_id: '',
    });
    setIsEdit(false);
    setOpen(false);
    setErrors({});
  };

  const handleChange = (e) => {
    setCurrentBuilding({ ...currentBuilding, [e.target.name]: e.target.value });
    setErrors({ ...errors, [e.target.name]: '' });
  };

  const validateFields = () => {
    const newErrors = {};
    if (!currentBuilding.name) newErrors.name = 'Building name is required';
    if (!currentBuilding.address) newErrors.address = 'Address is required';
    if (!currentBuilding.city) newErrors.city = 'City is required';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async () => {
    if (!validateFields()) return;

    const data = {
      name: currentBuilding.name || null,
      address: currentBuilding.address || null,
      city: currentBuilding.city || null,
      zone: currentBuilding.zone || null,
      latitude: currentBuilding.latitude || null,
      longitude: currentBuilding.longitude || null,
      agency_id: currentBuilding.agency_id || null,
      manager_id: currentBuilding.manager_id || null,
      master_meter_id: currentBuilding.master_meter_id || null,
    };

    try {
      if (isEdit) {
        await api.put(`/api/buildings/${currentBuilding.building_id}`, data);
        setNotification({ open: true, message: 'Building updated successfully!', severity: 'success' });
      } else {
        await api.post('/api/buildings', data);
        setNotification({ open: true, message: 'Building added successfully!', severity: 'success' });
      }
      fetchBuildings();
      handleClose();
    } catch (error) {
      console.error('Error saving building:', error);
      setNotification({ open: true, message: 'Error saving building. Please try again.', severity: 'error' });
    }
  };

  const handleDelete = async (building_id) => {
    try {
      await api.delete(`/api/buildings/${building_id}`);
      fetchBuildings();
      setNotification({ open: true, message: 'Building deleted successfully!', severity: 'success' });
    } catch (error) {
      console.error('Error deleting building:', error);
      setNotification({ open: true, message: 'Error deleting building. Please try again.', severity: 'error' });
    }
  };

  const handleNotificationClose = () => {
    setNotification({ ...notification, open: false });
  };

  return (
    <Container maxWidth="lg" style={{ paddingLeft: '10px', paddingRight: '10px', paddingBottom: '10px' }}>
      {/* Header and Button Section */}
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mb={1}
        mt={1}
        style={{
          borderBottom: '1px solid #ddd',
          padding: '6px 0',
          backgroundColor: '#fff',
          zIndex: 2,
          position: 'sticky',
          top: 0,
        }}
      >
        <Typography variant="h6" style={{ fontWeight: '500', color: '#1976d2' }}>
          Manage Buildings
        </Typography>
        <Button variant="contained" color="primary" onClick={() => handleOpen()} style={{ marginBottom: '4px' }}>
          Add Building
        </Button>
      </Box>

      {/* Scrollable Table Section */}
      <Box style={{ overflowY: 'auto', maxHeight: 'calc(100vh - 160px)', marginTop: '5px' }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: 'bold', backgroundColor: '#f8f9fa', padding: '6px 12px' }}>#</TableCell>
              {['Name', 'Address', 'City', 'Zone', 'Latitude', 'Longitude', 'Agency ID', 'Manager ID', 'Master Meter ID', 'Actions'].map((header) => (
                <TableCell key={header} style={{ fontWeight: 'bold', backgroundColor: '#f8f9fa', padding: '6px 12px', whiteSpace: 'nowrap' }}>
                  {header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {buildings.map((building, index) => (
              <TableRow
                key={building.building_id}
                style={{
                  height: '28px',
                  backgroundColor: index % 2 === 0 ? '#fdfdfd' : '#f6f6f6',
                  cursor: 'pointer',
                  transition: 'background-color 0.3s ease',
                }}
                onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = '#e9f7ff')}
                onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = index % 2 === 0 ? '#fdfdfd' : '#f6f6f6')}
              >
                <TableCell style={{ padding: '6px 12px' }}>{index + 1}</TableCell>
                <TableCell style={{ padding: '6px 12px' }}>{building.name}</TableCell>
                <TableCell style={{ padding: '6px 12px' }}>{building.address}</TableCell>
                <TableCell style={{ padding: '6px 12px' }}>{building.city}</TableCell>
                <TableCell style={{ padding: '6px 12px' }}>{building.zone}</TableCell>
                <TableCell style={{ padding: '6px 12px' }}>{building.latitude}</TableCell>
                <TableCell style={{ padding: '6px 12px' }}>{building.longitude}</TableCell>
                <TableCell style={{ padding: '6px 12px' }}>{building.agency_id}</TableCell>
                <TableCell style={{ padding: '6px 12px' }}>{building.manager_id}</TableCell>
                <TableCell style={{ padding: '6px 12px' }}>{building.master_meter_id}</TableCell>
                <TableCell style={{ padding: '6px 12px' }}>
                  <Box display="flex" flexDirection={{ xs: 'column', sm: 'row' }}>
                    <Button variant="outlined" color="secondary" onClick={() => handleOpen(building)} size="small">
                      Edit
                    </Button>
                    <Button variant="outlined" color="error" onClick={() => handleDelete(building.building_id)} style={{ marginLeft: { sm: '8px' } }} size="small">
                      Delete
                    </Button>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>

      {/* Add/Edit Dialog */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{isEdit ? 'Edit Building' : 'Add Building'}</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            label="Building Name"
            name="name"
            value={currentBuilding.name || ''}
            onChange={handleChange}
            fullWidth
            error={!!errors.name}
            helperText={errors.name}
          />
          <TextField
            margin="dense"
            label="Address"
            name="address"
            value={currentBuilding.address || ''}
            onChange={handleChange}
            fullWidth
            error={!!errors.address}
            helperText={errors.address}
          />
          <TextField
            margin="dense"
            label="City"
            name="city"
            value={currentBuilding.city || ''}
            onChange={handleChange}
            fullWidth
            error={!!errors.city}
            helperText={errors.city}
          />
          <TextField
            margin="dense"
            label="Zone"
            name="zone"
            value={currentBuilding.zone || ''}
            onChange={handleChange}
            fullWidth
          />
          <TextField
            margin="dense"
            label="Latitude"
            name="latitude"
            type="number"
            value={currentBuilding.latitude || ''}
            onChange={handleChange}
            fullWidth
          />
          <TextField
            margin="dense"
            label="Longitude"
            name="longitude"
            type="number"
            value={currentBuilding.longitude || ''}
            onChange={handleChange}
            fullWidth
          />
          <TextField
            margin="dense"
            label="Agency ID"
            name="agency_id"
            type="number"
            value={currentBuilding.agency_id || ''}
            onChange={handleChange}
            fullWidth
          />
          <TextField
            margin="dense"
            label="Manager ID"
            name="manager_id"
            type="number"
            value={currentBuilding.manager_id || ''}
            onChange={handleChange}
            fullWidth
          />
          <TextField
            margin="dense"
            label="Master Meter ID"
            name="master_meter_id"
            type="number"
            value={currentBuilding.master_meter_id || ''}
            onChange={handleChange}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit}>{isEdit ? 'Update' : 'Add'}</Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar for Notifications */}
      <Snackbar
        open={notification.open}
        autoHideDuration={4000}
        onClose={handleNotificationClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleNotificationClose} severity={notification.severity} sx={{ width: '100%' }}>
          {notification.message}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default ManageBuildings;

// src/components/AdminDashboard.js
import React from 'react';
import { Typography, Grid, Paper } from '@mui/material';
import MeterMapWithReadings from './MeterMapWithReadings';

function AdminDashboard() {
  console.log('AdminDashboard Rendered'); // Debugging statement

  return (
    <Grid container spacing={3} style={{ padding: '20px' }}>
      {/* Welcome or Overview */}
      <Grid item xs={12}>
        <Paper style={{ padding: '20px', textAlign: 'center' }}>
          <Typography variant="h4">Welcome, Super Admin</Typography>
          <Typography variant="body1" style={{ marginTop: '10px' }}>
            Use the navigation options above to manage users, meters, agencies, and other data.
          </Typography>
        </Paper>
      </Grid>

      {/* Meter Map with Readings */}
      <Grid item xs={12}>
        <Paper style={{ padding: '20px' }}>
          <Typography variant="h5" gutterBottom>
            Meter Locations
          </Typography>
          <MeterMapWithReadings />
        </Paper>
      </Grid>

      {/* Additional Overview Statistics (if needed) */}
      {/* Example: */}
      <Grid item xs={12} md={6}>
        <Paper style={{ padding: '20px' }}>
          <Typography variant="h6">Total Meters</Typography>
          <Typography variant="h4">120</Typography>
        </Paper>
      </Grid>
      <Grid item xs={12} md={6}>
        <Paper style={{ padding: '20px' }}>
          <Typography variant="h6">Active Users</Typography>
          <Typography variant="h4">45</Typography>
        </Paper>
      </Grid>
    </Grid>
  );
}

export default AdminDashboard;

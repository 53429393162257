// src/components/ManageFlats.js
import React, { useEffect, useState } from 'react';
import api from '../utils/api';
import { Typography, Container, Table, TableBody, TableCell, TableHead, TableRow, Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle, Box, Snackbar, Alert } from '@mui/material';

function ManageFlats() {
  const [flats, setFlats] = useState([]);
  const [open, setOpen] = useState(false);
  const [currentFlat, setCurrentFlat] = useState({
    flat_id: '',
    flat_number: '',
    floor: '',
    building_id: '',
    current_tenant_id: '',
    meter_id: '',
  });
  const [isEdit, setIsEdit] = useState(false);
  const [errors, setErrors] = useState({});
  const [notification, setNotification] = useState({ open: false, message: '', severity: 'success' });

  useEffect(() => {
    fetchFlats();
  }, []);

  const fetchFlats = async () => {
    try {
      const response = await api.get('/api/flats');
      setFlats(response.data);
    } catch (error) {
      console.error('Error fetching flats:', error);
    }
  };

  const handleOpen = (flat = { flat_id: '', flat_number: '', floor: '', building_id: '', current_tenant_id: '', meter_id: '' }) => {
    setCurrentFlat(flat);
    setIsEdit(!!flat.flat_id);
    setErrors({});
    setOpen(true);
  };

  const handleClose = () => {
    setCurrentFlat({ flat_id: '', flat_number: '', floor: '', building_id: '', current_tenant_id: '', meter_id: '' });
    setIsEdit(false);
    setOpen(false);
    setErrors({});
  };

  const handleChange = (e) => {
    setCurrentFlat({ ...currentFlat, [e.target.name]: e.target.value });
    setErrors({ ...errors, [e.target.name]: '' });
  };

  const validateFields = () => {
    const newErrors = {};
    if (!currentFlat.flat_number) newErrors.flat_number = 'Flat number is required';
    if (!currentFlat.floor) newErrors.floor = 'Floor is required';
    if (!currentFlat.building_id) newErrors.building_id = 'Building ID is required';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async () => {
    if (!validateFields()) return;

    const data = {
      flat_number: currentFlat.flat_number || null,
      floor: currentFlat.floor || null,
      building_id: currentFlat.building_id || null,
      current_tenant_id: currentFlat.current_tenant_id || null,
      meter_id: currentFlat.meter_id || null,
    };

    try {
      if (isEdit) {
        await api.put(`/api/flats/${currentFlat.flat_id}`, data);
        setNotification({ open: true, message: 'Flat updated successfully!', severity: 'success' });
      } else {
        await api.post('/api/flats', data);
        setNotification({ open: true, message: 'Flat added successfully!', severity: 'success' });
      }
      fetchFlats();
      handleClose();
    } catch (error) {
      console.error('Error saving flat:', error);
      setNotification({ open: true, message: 'Error saving flat. Please try again.', severity: 'error' });
    }
  };

  const handleDelete = async (flat_id) => {
    try {
      await api.delete(`/api/flats/${flat_id}`);
      fetchFlats();
      setNotification({ open: true, message: 'Flat deleted successfully!', severity: 'success' });
    } catch (error) {
      console.error('Error deleting flat:', error);
      setNotification({ open: true, message: 'Error deleting flat. Please try again.', severity: 'error' });
    }
  };

  const handleNotificationClose = () => {
    setNotification({ ...notification, open: false });
  };

  return (
    <Container maxWidth="lg" style={{ paddingLeft: '10px', paddingRight: '10px', paddingBottom: '10px' }}>
      {/* Header and Button Section */}
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mb={1}
        mt={1}
        style={{
          borderBottom: '1px solid #ddd',
          padding: '6px 0',
          backgroundColor: '#fff',
          zIndex: 2,
          position: 'sticky',
          top: 0,
        }}
      >
        <Typography variant="h6" style={{ fontWeight: '500', color: '#1976d2' }}>
          Manage Flats
        </Typography>
        <Button variant="contained" color="primary" onClick={() => handleOpen()} style={{ marginBottom: '4px' }}>
          Add Flat
        </Button>
      </Box>

      {/* Scrollable Table Section */}
      <Box style={{ overflowY: 'auto', maxHeight: 'calc(100vh - 160px)', marginTop: '5px' }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: 'bold', backgroundColor: '#f8f9fa', padding: '6px 12px' }}>#</TableCell>
              {['Flat Number', 'Floor', 'Building ID', 'Current Tenant ID', 'Meter ID', 'Actions'].map((header) => (
                <TableCell key={header} style={{ fontWeight: 'bold', backgroundColor: '#f8f9fa', padding: '6px 12px', whiteSpace: 'nowrap' }}>
                  {header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {flats.map((flat, index) => (
              <TableRow
                key={flat.flat_id}
                style={{
                  height: '28px',
                  backgroundColor: index % 2 === 0 ? '#fdfdfd' : '#f6f6f6',
                  cursor: 'pointer',
                  transition: 'background-color 0.3s ease',
                }}
                onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = '#e9f7ff')}
                onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = index % 2 === 0 ? '#fdfdfd' : '#f6f6f6')}
              >
                <TableCell style={{ padding: '6px 12px' }}>{index + 1}</TableCell>
                <TableCell style={{ padding: '6px 12px' }}>{flat.flat_number}</TableCell>
                <TableCell style={{ padding: '6px 12px' }}>{flat.floor}</TableCell>
                <TableCell style={{ padding: '6px 12px' }}>{flat.building_id}</TableCell>
                <TableCell style={{ padding: '6px 12px' }}>{flat.current_tenant_id}</TableCell>
                <TableCell style={{ padding: '6px 12px' }}>{flat.meter_id}</TableCell>
                <TableCell style={{ padding: '6px 12px' }}>
                  <Box display="flex" flexDirection={{ xs: 'column', sm: 'row' }}>
                    <Button variant="outlined" color="secondary" onClick={() => handleOpen(flat)} size="small">
                      Edit
                    </Button>
                    <Button variant="outlined" color="error" onClick={() => handleDelete(flat.flat_id)} style={{ marginLeft: { sm: '8px' } }} size="small">
                      Delete
                    </Button>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>

      {/* Add/Edit Dialog */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{isEdit ? 'Edit Flat' : 'Add Flat'}</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            label="Flat Number"
            name="flat_number"
            value={currentFlat.flat_number || ''}
            onChange={handleChange}
            fullWidth
            error={!!errors.flat_number}
            helperText={errors.flat_number}
          />
          <TextField
            margin="dense"
            label="Floor"
            name="floor"
            value={currentFlat.floor || ''}
            onChange={handleChange}
            fullWidth
            error={!!errors.floor}
            helperText={errors.floor}
          />
          <TextField
            margin="dense"
            label="Building ID"
            name="building_id"
            type="number"
            value={currentFlat.building_id || ''}
            onChange={handleChange}
            fullWidth
            error={!!errors.building_id}
            helperText={errors.building_id}
          />
          <TextField
            margin="dense"
            label="Current Tenant ID"
            name="current_tenant_id"
            type="number"
            value={currentFlat.current_tenant_id || ''}
            onChange={handleChange}
            fullWidth
          />
          <TextField
            margin="dense"
            label="Meter ID"
            name="meter_id"
            type="number"
            value={currentFlat.meter_id || ''}
            onChange={handleChange}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit}>{isEdit ? 'Update' : 'Add'}</Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar for Notifications */}
      <Snackbar
        open={notification.open}
        autoHideDuration={4000}
        onClose={handleNotificationClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleNotificationClose} severity={notification.severity} sx={{ width: '100%' }}>
          {notification.message}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default ManageFlats;
